
import Vue from 'vue'
import Component from 'vue-class-component'

import {
  UrlRequestCallable,
  urlRequestId
} from '@codee-club/common/dist/api-callables'

import Routes from '@/router/routes'

@Component
export default class NuConnectButton extends Vue {
  async attemptNUConnectLogin(): Promise<void> {
    const callbackPath = this.$router.resolve({
      name: Routes.UserLoginCallback
    }).href
    const redirectUri = window.location.origin + callbackPath
    const authNUConnectURL: UrlRequestCallable = this.$functions.httpsCallable(
      urlRequestId
    )
    const result = await authNUConnectURL({ redirectUri })
    window.location.href = result.data
  }
}
